export default {
  toggleApp: null,
  toggleBukasendReturn: null,
  togglePickupFailed: null,
  toggleBukasendDesktop: null,
  toggleTransactionReferrer: null,
  toggleHandlingBackDeviceEnabled: null,
  toggleMitraPrintReceipt: null,
  toggleDeliveryInsurance: null,
  toggleMitraBulkPrintReceipt: null,
  toggleCourierPickupTimeRequest: null,
  toggleOnboarding: null,
  toggleManualBookingEnabled: null,
  toggleDesktopRandomPinpointEnabled: null,
  toggleSetLocationInCourierListEnabled: null,
  toggleCashback3plEnabled: null,
  toggleAllowUserCancelTransaction: null,
  toggleAutoTopupCashback: null,
  toggleUspBoarding: null,
  toggleNewRecipientNameValidationEnabled: null,
  toggleSenderGeolocationFlagEnabled: null,
  toggleDiscrepancyChangesAlertEnabled: null,
  toggleCancelTransaction: null,
  toggleDiscrepancyDetailEnabled: null,
  toggleOndemandLiveTrackingUrlEnabled: null,
  toggleSaveHandlingEnabled: null,
  toggleTransactionRecapEnabled: null,
  toggleTransactionRecapInLandingEnabled: null,
  toggleTransactionRecapDownloadEnabled: null,
  toggleBukasendReturnPickupEnabled: null,
  togglePermissionsByRoleEnabled: null,
  toggleTestimoniesEnabled: null,
  toggleOndemandCourierServicesConfigEnabled: null,
  toggleMembershipEnabled: null,
  toggleVoucherEnabled: null,
  toggleOngkirGokilEnabled: null,
  toggleTrxDetailVoucherInfoEnabled: null,
  toggleProfilePageEnabled: null,
  togglePickupGuaranteeNewUser: null,
  toggleCodEnabled: null,
  toggleCodDropoffEnabled: null,
  togglePartnerCodDropoffEnabled: null,
  toggleInsuranceProductPriceEnabled: null,
  toggleDeliveryWithCoolbox: null,
  togglePhoneVerificationEnabled: null,
  toggleAddressRevampEnabled: null,
  toggleKycEnabled: null,
  togglePickupGuaranteeAllUserEnabled: null,
  toggleLandingVoucherBannerEnabled: null,
  toggleHomeVoucherBannerEnabled: null,
  toggleEncyclopediaAgentEnabled: null,
  toggleShopifyExtensionEnabled: null,
  toggleHomeDwebEnabled: null,
  toggleProfileDwebEnabled: null,
  toggleDeliveryCostCheckDwebEnabled: null,
  toggleAddressBookEnabled: null,
  toggleTransactionsPageDwebEnabled: null,
  toggleHomePageDopeEnabled: null,
  toggleMadoEnabled: null,
  toggleForceKycValidationEnabled: null,
  toggleSendBulkDraftEnabled: null,
  toggleAllowUserFillBothWeightEnabled: null,
  togglePartnerHidePromoEnabled: null,
  togglePartnerSkipCheckoutEnabled: null,
  togglePartnerRoleEnabled: null,
  toggleDiscrepanyBlockTransactionEnabled: null,
  toggleLandingCodBannerEnabled: null,
  toggleRevampLandingV2Enabled: null,
  togglePromotionBudgetEnabled: null,
  togglePromotionTokenEnabled: null,
  toggleMembershipRuleEnabled: null,
  toggleCostSplitEnabled: null,
  toggleNonMembershipEnabled: null,
  toggleIdempotencyEnabled: null,
  toggleIdempotencyRegenerateUniqueIdEnabled: null,
  toggleDiffServiceTypeCashbackEnabled: null,
  toggleLimitBulkUploadUserEnabled: null,
  toggleLimitCODTransactionEnabled: null,
  toggleNewFlowKycEnabled: null,
  toggleMadoBukasendKycEnabled: null,
  toggleBlockDukcapilSubmittedEnabled: null,
  toggleMitraWalletEnabled: null,
  toggleMitraWalletBlockTransactionEnabled: null,
  toggleMitraWalletAnotherEntryPoint: null,
  toggleRegisterMitraAgentPhoneVerifiedEnabled: null,
  toggleTransactionListRevampEnabled: null,
  toggleBannerSliderEndpointEnabled: null,
  toggleMembershipRevampEnabled: null,
  toggleCheckDukcapilEnabled: null,
  toggleOngkirGokilIntegrationEnabled: null,
  toggleMultiplePromotionEnabled: null,
  toggleMitraSubdomainEnabled: null,
  toggleDisableMultiplePackageEnabled: null,
};
