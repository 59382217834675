/* eslint-disable import/prefer-default-export */
const TOGGLE_PREFIX = 'bukapengiriman/toggle/';
const TOGGLE_PREFIX_ALT = 'bukasend-';

const NEO_TOGGLE_KEYS = {
  BUKASEND_APP: 'bukapengiriman/toggle',
  PICKUP_FAILED: `${TOGGLE_PREFIX}pickup_failed`,
  BUKASEND_DESKTOP: `${TOGGLE_PREFIX}bukasend_desktop`,
  REFERRAL: `${TOGGLE_PREFIX}referral`,
  HANDLING_BACK_DEVICE_ENABLED: `${TOGGLE_PREFIX_ALT}handling-back-device-enabled`,
  MITRA_PRINT_RECEIPT: 'mitra-bukasend-print-receipt-enabled',
  MITRA_BULK_PRINT_RECEIPT: 'mitra-bukasend-bulk-print-receipt-enabled',
  BUKASEND_RETURN: `${TOGGLE_PREFIX_ALT}return-enabled`,
  DELIVERY_INSURANCE: `${TOGGLE_PREFIX_ALT}delivery-insurance-enabled`,
  ON_BOARDING: `${TOGGLE_PREFIX}onboarding`,
  COURIER_PICKUP_TIME_REQUEST: `${TOGGLE_PREFIX}courier-pickup-time-request`,
  GRAB_AUTO_BOOKING_DISABLED: `${TOGGLE_PREFIX}grab_auto_booking_disabled`,
  MANUAL_BOOKING_ENABLED: `${TOGGLE_PREFIX}manual_booking`,
  DESKTOP_RANDOM_PINPOINT_ENABLED: `${TOGGLE_PREFIX}desktop_random_pinpoint_enabled`,
  SET_LOCATION_IN_COURIER_LIST_ENABLED: `${TOGGLE_PREFIX}set_location_in_courier_list_enabled`,
  CASHBACK_3PL_ENABLED: `${TOGGLE_PREFIX}cashback_3pl`,
  ALLOW_USER_CANCEL_TRANSACTION: `${TOGGLE_PREFIX}allow_user_cancel_transaction`,
  AUTO_TOPUP_CASHBACK: `${TOGGLE_PREFIX}auto_topup_cashback`,
  USP_BOARDING: `${TOGGLE_PREFIX}usp_boarding`,
  NEW_RECIPIENT_NAME_VALIDATION_ENABLED: `${TOGGLE_PREFIX}new_recipient_name_validation_enabled`,
  SENDER_GEOLOCATION_FLAG_ENABLED: `${TOGGLE_PREFIX}sender_geolocation_flag_enabled`,
  DISCREPANCY_CHANGES_ALERT_ENABLED: `${TOGGLE_PREFIX}discrepancy_changes_alert_enabled`,
  CANCEL_TRANSACTION: `${TOGGLE_PREFIX_ALT}cancel_transaction`,
  DISCREPANCY_DETAIL_ENABLED: `${TOGGLE_PREFIX}discrepancy_detail_enabled`,
  JUTAWAN_FEATURE_DISABLED: 'jutawan/toggle/feature_disabled',
  JUTAWAN_COD: 'jutawan/toggle/cod',
  SHIPPING_ESTIMATION_V4: 'xpr-migrate-shipping-estimation-checkout-to-v4-enabled',
  ONDEMAND_LIVE_TRACKING_URL_ENABLED: `${TOGGLE_PREFIX}ondemand_live_tracking_url_enabled`,
  SAVE_HANDLING_ENABLED: `${TOGGLE_PREFIX}save_handling_enabled`,
  TRANSACTION_RECAP_ENABLED: `${TOGGLE_PREFIX}transaction_recap_enabled`,
  TRANSACTION_RECAP_IN_LANDING_ENABLED: `${TOGGLE_PREFIX}transaction_recap_in_landing_enabled`,
  TRANSACTION_RECAP_DOWNLOAD_ENABLED: `${TOGGLE_PREFIX}transaction_recap_download_enabled`,
  BUKASEND_RETURN_PICKUP_ENABLED: `${TOGGLE_PREFIX}bukasend_return_pickup_enabled`,
  PERMISSIONS_BY_ROLE_ENABLED: `${TOGGLE_PREFIX}permissions_by_role_enabled`,
  TESTIMONIES_ENABLED: `${TOGGLE_PREFIX}testimonies_enabled`,
  ONDEMAND_COURIER_SERVICES_CONFIG_ENABLED: `${TOGGLE_PREFIX}ondemand_courier_services_config_enabled`,
  MEMBERSHIPS_ENABLED: `${TOGGLE_PREFIX}memberships_enabled`,
  VOUCHER_ENABLED: `${TOGGLE_PREFIX}voucher_enabled`,
  ONGKIR_GOKIL_ENABLED: `${TOGGLE_PREFIX}promo_ongkir_gokil_enabled`,
  TRX_DETAIL_VOUCHER_INFO_ENABLED: `${TOGGLE_PREFIX}trx_detail_voucher_info_enabled`,
  PROFILE_PAGE_ENABLED: `${TOGGLE_PREFIX}profile_page_enabled`,
  PICKUP_GUARANTEE_NEW_USER: `${TOGGLE_PREFIX}pickup_guarantee_new_user`,
  BUKASEND_COD_ENABLED: `${TOGGLE_PREFIX}cod_enabled`,
  BUKASEND_COD_DROPOFF_ENABLED: `${TOGGLE_PREFIX}cod_dropoff_enabled`,
  BUKASEND_PARTNER_COD_DROPOFF_ENABLED: `${TOGGLE_PREFIX}partner_cod_dropoff_enabled`,
  INSURANCE_PRODUCT_PRICE_ENABLED: `${TOGGLE_PREFIX}insurance_product_price_enabled`,
  DELIVERY_WITH_COOLBOX_ENABLED: `${TOGGLE_PREFIX}delivery_with_coolbox_enabled`,
  PHONE_VERIFICATION_ENABLED: `${TOGGLE_PREFIX}phone_verification_enabled`,
  ADDRESS_REVAMP_ENABLED: `${TOGGLE_PREFIX}address_revamp_enabled`,
  KYC_ENABLED: `${TOGGLE_PREFIX}kyc_enabled`,
  PICKUP_GUARANTEE_ALL_USER_ENABLED: `${TOGGLE_PREFIX}pickup_guarantee_all_user`,
  LANDING_VOUCHER_BANNER_ENABLED: `${TOGGLE_PREFIX}landing_voucher_banner_enabled`,
  HOME_VOUCHER_BANNER_ENABLED: `${TOGGLE_PREFIX}home_voucher_banner_enabled`,
  ENCYCLOPEDIA_AGENT_ENABLED: `${TOGGLE_PREFIX}encyclopedia_agent_enabled`,
  SHOPIFY_EXTENSION_ENABLED: `${TOGGLE_PREFIX}shopify_extension_enabled`,
  HOME_DWEB_ENABLED: `${TOGGLE_PREFIX}home_dweb_enabled`,
  PROFILE_DWEB_ENABLED: `${TOGGLE_PREFIX}profile_dweb_enabled`,
  DELIVERY_COST_CHECK_DWEB_ENABLED: `${TOGGLE_PREFIX}delivery_cost_check_dweb_enabled`,
  ADDRESS_BOOK_ENABLED: `${TOGGLE_PREFIX}address_book_enabled`,
  TRANSACTIONS_PAGE_DWEB_ENABLED: `${TOGGLE_PREFIX}transactions_page_dweb_enabled`,
  SEND_BULK_DRAFT_ENABLED: `${TOGGLE_PREFIX}send_bulk_draft_enabled`,
  HOME_PAGE_DOPE_ENABLED: `${TOGGLE_PREFIX}home_page_dope_enabled`,
  MADO_ENABLED: `${TOGGLE_PREFIX}mado_enabled`,
  FORCE_KYC_VALIDATION_ENABLED: `${TOGGLE_PREFIX}force_kyc_validation_enabled`,
  ALLOW_USER_FILL_BOTH_WEIGHT_ENABLED: `${TOGGLE_PREFIX}allow_user_fill_both_weight_enabled`,
  PARTNER_HIDE_PROMO_ENABLED: `${TOGGLE_PREFIX}partner_hide_promo_enabled`,
  PARTNER_SKIP_CHECKOUT_ENABLED: `${TOGGLE_PREFIX}partner_skip_checkout_enabled`,
  PARTNER_ROLE_ENABLED: `${TOGGLE_PREFIX}partner_role_enabled`,
  DISCREPANCY_BLOCK_TRANSACTION_ENABLED: `${TOGGLE_PREFIX}discrepancy_block_transaction_enabled`,
  LANDING_COD_BANNER_ENABLED: `${TOGGLE_PREFIX}landing_cod_banner_enabled`,
  REVAMP_LANDING_V2_ENABLED: `${TOGGLE_PREFIX}revamp_landing_v2_enabled`,
  PROMOTION_BUDGET_ENABLED: `${TOGGLE_PREFIX}budget_promotion_enabled`,
  PROMOTION_TOKEN_ENABLED: `${TOGGLE_PREFIX}promotion_token_enabled`,
  MEMBERSHIP_RULE_ENABLED: `${TOGGLE_PREFIX}membership_rule_enabled`,
  COST_SPLIT_ENABLED: `${TOGGLE_PREFIX}cost_split_enabled`,
  NON_MEMBERSHIP_ENABLED: `${TOGGLE_PREFIX}non_membership_enabled`,
  IDEMPOTENCY_ENABLED: `${TOGGLE_PREFIX}idempotency_enabled`,
  IDEMPOTENCY_REGENERATE_UNIQUE_ID_ENABLED: `${TOGGLE_PREFIX}idempotency_regenerate_unique_id_enabled`,
  DIFF_SERVICE_TYPE_CASHBACK_ENABLED: `${TOGGLE_PREFIX}differentiate_service_type_cashback_enabled`,
  LIMIT_BULK_UPLOAD_USER_ENABLED: `${TOGGLE_PREFIX}limit_bulk_upload_user_enabled`,
  LIMIT_COD_TRANSACTION_ENABLED: `${TOGGLE_PREFIX}limit_cod_transaction_enabled`,
  NEW_FLOW_KYC_ENABLED: `${TOGGLE_PREFIX}new_flow_kyc_enabled`,
  MADO_BUKASEND_KYC_ENABLED: `${TOGGLE_PREFIX}mado_bukasend_kyc_enabled`,
  BLOCK_DUKCAPIL_SUBMITTED_ENABLED: `${TOGGLE_PREFIX}block_dukcapil_submitted_enabled`,
  MITRA_WALLET_ENABLED: `${TOGGLE_PREFIX}mitra_wallet_enabled`,
  MITRA_WALLET_BLOCK_TRANSACTION_ENABLED: `${TOGGLE_PREFIX}mitra_wallet_block_transaction_enabled`,
  MITRA_WALLET_ANOTHER_ENTRY_POINT_ENABLED: `${TOGGLE_PREFIX}mitra_wallet_another_entry_point_enabled`,
  REGISTER_MITRA_AGENT_PHONE_VERIFIED_ENABLED: `${TOGGLE_PREFIX}register_mitra_agent_phone_verified_enabled`,
  TRANSACTION_LIST_REVAMP_ENABLED: `${TOGGLE_PREFIX}transaction_list_revamp_enabled`,
  BANNER_SLIDER_ENDPOINT_ENABLED: `${TOGGLE_PREFIX}banner_slider_endpoint_enabled`,
  MEMBERSHIP_REVAMP_ENABLED: `${TOGGLE_PREFIX}membership_revamp_enabled`,
  CHECK_DUKCAPIL_ENABLED: `${TOGGLE_PREFIX}check_dukcapil`,
  ONGKIR_GOKIL_INTEGRATION_ENABLED: `${TOGGLE_PREFIX}ongkir_gokil_integration_enabled`,
  MULTIPLE_PROMOTION_INTEGRATION_ENABLED: `${TOGGLE_PREFIX}multiple_promotion_integration_enabled`,
  MITRA_SUBDOMAIN_ENABLED: 'bukapengiriman/toggle/bukasend_mitra_subdomain_enabled',
  DISABLE_MULTIPLE_PACKAGE_ENABLED: `${TOGGLE_PREFIX}disable_multiple_package_enabled`,
};

const NEO_CONFIG_KEYS = {
  BUKASEND_CUSTOM_FIELD_WHITELIST_USERS: `${TOGGLE_PREFIX_ALT}custom-field-whitelist-users`,
  BUKASEND_AVAILABLE_COURIER_CONFIG: `${TOGGLE_PREFIX_ALT}available-courier-images-config`,
  BUKASEND_PROMO_CONFIG: `${TOGGLE_PREFIX_ALT}promo-config`,
  BUKASEND_PRINT_CONFIG: `${TOGGLE_PREFIX_ALT}print-config`,
  BUKASEND_EXCLUDE_PICKUP_FAILED_COURIERS: `${TOGGLE_PREFIX_ALT}excluded-pickup-failed-couriers`,
  BUKASEND_CASHBACK_LABEL: `${TOGGLE_PREFIX_ALT}cashback-label`,
  BUKASEND_PACKAGE_CONTENT_CATEGORIES: `${TOGGLE_PREFIX_ALT}package-content-categories`,
  BUKASEND_MANUAL_BOOKING_COURIER_SERVICES: `${TOGGLE_PREFIX_ALT}manual-booking-courier-services`,
  BUKASEND_GRAB_SLA_CONFIG: `${TOGGLE_PREFIX_ALT}grab-sla-config`,
  BUKASEND_MISC_CONFIG: `${TOGGLE_PREFIX_ALT}misc-config`,
  BUKASEND_REBOOK_CONFIG: `${TOGGLE_PREFIX_ALT}rebook-config`,
  BUKASEND_COURIER_OPS_TIME_CONFIG: `${TOGGLE_PREFIX_ALT}courier-ops-time-config`,
  BUKASEND_CANCELLATION_CONFIG: `${TOGGLE_PREFIX_ALT}cancellation-config`,
  BUKASEND_ONDEMAND_COURIER_SERVICES: `${TOGGLE_PREFIX_ALT}ondemand-courier-services`,
  BUKASEND_VOUCHER_CONFIG: `${TOGGLE_PREFIX_ALT}voucher-config`,
  BUKASEND_ONGKIR_GOKIL_CONFIG: `${TOGGLE_PREFIX_ALT}promo-ongkir-gokil-config`,
  BUKASEND_PICKUP_GUARANTEE_CONFIG: `${TOGGLE_PREFIX_ALT}pickup-guarantee-config`,
  BUKASEND_ENCYCLOPEDIA_AGENT_CONFIG: `${TOGGLE_PREFIX_ALT}encyclopedia-agent-config`,
  BUKASEND_COURIER_LANDING_CONFIG: `${TOGGLE_PREFIX_ALT}courier-landing-%COURIER_CODE%-config`,
  BUKASEND_COD_CONFIG: `${TOGGLE_PREFIX_ALT}cod-config`,
  BUKASEND_WEIGHT_TNC_CONFIG: `${TOGGLE_PREFIX_ALT}weight-tnc-config`,
  BUKASEND_BENEFITS_CONFIG: `${TOGGLE_PREFIX_ALT}benefits-config`,
  BUKASEND_COURIER_SELECTION_CONFIG: `${TOGGLE_PREFIX_ALT}courier-selection-config`,
  BUKASEND_SET_PAID_DISCREPANCY_CONFIG: `${TOGGLE_PREFIX_ALT}set-paid-discrepancy-config`,
  BUKASEND_ANNOUNCEMENT_BANNER_CONFIG: `${TOGGLE_PREFIX_ALT}announcement-banner-config`,
  BUKASEND_LOST_BROKEN_CONFIG: `${TOGGLE_PREFIX_ALT}lost-broken-config`,
};

module.exports = {
  NEO_TOGGLE_KEYS,
  NEO_CONFIG_KEYS,
};
